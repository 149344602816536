module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://myfoodblog.nl/graphql","develop":{"hardCacheData":false,"hardCacheMediaFiles":true,"nodeUpdateInterval":5000},"production":{"hardCacheMediaFiles":true,"allow404Images":true,"allow401Images":true},"schema":{"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"type":{"Post":{"limit":100},"Recipe":{"limit":100},"Hotspot":{"limit":100},"Video":{"limit":100},"Tag":{"limit":100},"MediaItem":{"localFile":{"requestConcurrency":100,"excludeByMimeTypes":[],"maxFileSizeBytes":15728640},"createFileNodes":true,"lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"html":{"imageQuality":50,"generateWebpImages":true,"useGatsbyImage":true,"imageMaxWidth":null,"fallbackImageMaxWidth":100,"createStaticFiles":true},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"My Foodblog","short_name":"My Foodblog","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/images/favicon/favicon-16x16.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fddcfc4496c8d7ada8ff855fafd84904"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
